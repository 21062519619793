import Router from 'next/router'
import Image from "next/legacy/image";
import { useState, useEffect } from 'react'
import { useRouter } from 'next/router'
import { useSession } from 'next-auth/react'
import { awsbucket, cnf } from '../envs'

export default function Header (props) {
    const router = useRouter()
    const { data: session } = useSession()
    const [fromapp, setFromapp] = useState(false)

    useEffect(()=>{
        if(router.isReady){
            let objKeys = Object.keys(router.query)
            if(objKeys.length > 0){
                //try to check values included..
                //1 cat..
                if(objKeys.indexOf('app') != -1){
                    if(router.query.app){
                        setFromapp(true)
                    }
                }
            }
        }
    },[router.isReady])

    const gotoapp = () => {
        let urlink = cnf == "prod" ? "helphero" : "helpherostg"
        Router.push(`${urlink}://appredirect?token=`)
    }

    const goDonate = () => {
        //Router.back()
        Router.replace('/donate')
        //Router.push('/donate')
    }

    const renderLoggedIn=()=>{
        let skip = ['setup','settings','forgotpassword','thankyou','terms','privacy','faq']
        if(skip.indexOf(props.page) != -1){
            return null;
        }else{
            if(session && session.user){
                let p = session.user.photo ? session.user.photo != 'null' && session.user.photo != 'undefined' ? 'https://'+awsbucket+'.s3.amazonaws.com/profile/'+session.user.photo : '/placeholder.png' : '/placeholder.png'
                let un = session.user.names
                if(session.user.photo && session.user.photo != "null" && session.user.photo != 'undefined'){
                    return (
                        <div onClick={()=>Router.push('/settings')} style={{
                            display:'flex',
                            flexDirection:'row',
                            alignItems:'center',
                            justifyContent:'center'
                        }}>
                            <img src={p} alt="HelpHero" style={{
                                width:'36px',
                                borderRadius:'18px',
                                border:'1.5px solid white'
                            }}/>
                        </div>
                    )
                }else{
                    if(un){
                        return (
                            <div onClick={()=>Router.push('/settings')} style={{
                                display:'flex',
                                flexDirection:'row',
                                alignItems:'center',
                                justifyContent:'center',
                                width:36,
                                height:36,
                                border:'1.5px solid white',
                                borderRadius:'18px',
                                fontSize:12,
                                color:'white',
                                backgroundColor:'#999999'
                            }}>
                                {un.split(" ").map(name => name[0]).join(" ").toUpperCase()}
                            </div>
                        )
                    }else{
                        return (
                            <div onClick={()=>Router.push('/settings')} style={{
                                display:'flex',
                                flexDirection:'row',
                                alignItems:'center',
                                justifyContent:'center'
                            }}>
                                <img src={p} alt="HelpHero" style={{
                                    width:'36px',
                                    borderRadius:'18px',
                                    border:'1.5px solid white'
                                }}/>
                            </div>
                        )
                    }
                }
            }else{
                return (
                    <div style={{
                        display:'flex',
                        flexDirection:'row',
                        alignItems:'center',
                        justifyContent:'center',
                        cursor:'pointer',
                        paddingRight:8
                    }} onClick={()=>props.goLogin()}>
                        {/*<div>
                            <img src="/lock_white.png" style={{ width:18 }} alt="HelpHero"/>
                        </div>*/}
                        <div style={{ color:'white', paddingBottom:2, paddingLeft:2}}>
                            Log In
                        </div>
                    </div>
                )
            }
        }
    }

    const goHome = () => {
        Router.push('https://helphero.io')
    }

    const renderCenter=(p)=>{
        if(p == "settings"){
            if(session && session.user){
                if(session.user.cognitoId != "" && session.user.cognitoId != 'null'){
                    if(session.user.username != "null"){
                        return (
                            <div style={{ color:'white', fontWeight:'bold' }}>@{session.user.username}</div>
                        )
                    }else{
                        return (
                            <a href="https://helphero.io"><img src="/logo_white.png" style={{ width: 95 }}/></a>
                        )
                    }
                }else{
                    return (
                        <a href="https://helphero.io"><img src="/logo_white.png" style={{ width: 95 }}/></a>
                    )
                }
            }else{
                return (
                    <a href="https://helphero.io"><img src="/logo_white.png" style={{ width: 95 }}/></a>
                )
            }
        }else{
            let obj = {
                "forgotpassword":"Forgot Password",
                "receipts":"Receipts",
                "changepassword":"Change Password",
                "subscriptions":"Recurring Donations",
                "modify":"Modify Recurring Subscription",
                "privacy":"Privacy Policy",
                "terms":"Terms of Service",
                "faq":"FAQ"
            }
            let objkeys = Object.keys(obj)
            if(objkeys.indexOf(props.page) != -1){
                return (
                    <div style={{ color:'white', fontWeight:'bold' }}>{obj[props.page]}</div>
                )
            }else{
                return (
                    <a href="https://helphero.io"><img src="/logo_white.png" style={{ width: 95 }}/></a>
                )
            } 
        }
    }

    const goBack=()=>{
        if(props.page == "thankyou"){
            goDonate()
        }else{
            if(props.page == "setup"){
                if(session && session.user){
                    goDonate()
                }else{
                    Router.back()
                }
            }else{
                Router.back()
            }
        }
    }

    let fullDown=["donate","settings"]
    let def = props.page && fullDown.indexOf(props.page) != -1 ? "top_d":"top"
    let r = Object.keys(props).indexOf('showdonate') != -1 ? props.showdonate ? "top_d" : def : def
    let pw = ['modify','settings','setup','subscriptions','terms','receipts','privacy','faq','changepassword','contactus','forgotpassword','completesetup','thankyou']
    return (
        <>
            <div className={r}>
                <div className="logo" style={{ height: 60 }}>
                    <div style={{ width:80 }}></div>
                    <div style={{ display:'flex', flex:1, alignItems:'center', justifyContent:'center', paddingTop:5 }}>
                        {renderCenter(props.page)}
                    </div>
                    <div style={{ display:'flex', alignItems:'center', justifyContent:'center', width:80 }}>
                        {renderLoggedIn()}
                    </div>
                </div>
                {fromapp ? (
                    <div className='ldp' onClick={gotoapp}>
                        <img src="/back.png" style={{ height:'30px' }}/>
                    </div>
                ) : pw.indexOf(props.page) != -1 ? (
                    <div className='ldp' onClick={goBack}>
                        <img src="/back.png" style={{ height:'30px' }}/>
                    </div>
                ) : (<div/>)}
                {r == "top_d" ? (<div style={{ height:'6rem' }}/>):(<div style={{ height:40 }}/>)}
            </div>

            <style jsx>{`
                .ldp { display:none; }
                .pff {
                    width:36px;
                    border-radius: 18px;
                    border: 1.5px solid white;
                }
                .logo {
                    width:800px;
                    padding-left:10px;
                    padding-right:10px;
                    display: flex;
                    flex-direction: row;
                    align-items:center;
                    justify-content: space-between;
                }

                .logo_img {
                    width: 95px;
                }
                .logo_img_d {
                    width: 95px;
                }
                .top {
                    display:flex;
                    align-items: center;
                    justify-content:center;
                    //flex-basis:3rem;
                    flex-basis:3.6rem;
                    //margin-top:-22px;
                    background-color:#f9b100 0;
                    background-image: linear-gradient(#f9b100, #e9821b);
                    box-shadow:0 4px 12px rgb(0 0 0 / 30%);
                }
                .top_d {
                    display:flex;
                    align-items: center;
                    justify-content:center;
                    flex-basis:7.5rem;
                    //margin-top:-22px;
                    background-color:#f9b100 0;
                    background-image: linear-gradient(#f9b100, #e9821b);
                    box-shadow:0 4px 12px rgb(0 0 0 / 30%);
                }
                @media (max-width: 600px) {
                    .ldp { display:block; position:absolute; left:22px; top:15px; }
                    .top_d {
                        align-items:flex-start !important;
                        //flex-basis:7rem;
                        //flex-basis:3rem;


                        position: -webkit-sticky;
                        position: sticky;
                        top: 0;
                        z-index: 10;
                    }
                    .top {
                        align-items:flex-start !important;
                        position: -webkit-sticky;
                        position: sticky;
                        top: 0;
                        z-index: 10;
                    }
                    .logo {
                        align-items: center;
                        justify-content: space-between;
                        display: flex;
                        flex-direction:row;
                        //padding-bottom:8px;
                    }

                    .logo_img {
                        width:100px;
                    }

                    .logo_img_d {
                        width: 80px;
                    }
                }
            `}</style>

            <style jsx global>{`
                html,
                body {
                padding: 0;
                margin: 0;
                font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
                    Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
                    sans-serif;
                }

                * {
                box-sizing: border-box;
                }
            `}</style>
        </>
    )
}